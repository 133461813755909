import React, {} from 'react';
import './Footer.css';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <footer className='footer' id="footer">
            <Container>
                <div className='heading'>/ How can we help?</div>
                <h2 className='title'>Tell us about <b>Your Project</b></h2>
                <Link to="/contact" className='button'>Contact Now <i className="bi bi-arrow-right"></i> </Link>
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='footer_link'>
                            <a href='tel:+916359611740'><i className="bi bi-phone"></i></a>
                            <h4>Talk to Sales</h4>
                            <p>+91 6359 611 740</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='footer_link'>
                        <a href='mailto:asksoftsurat@gmail.com'><i className="bi bi-envelope-open"></i></a>
                            <h4>Contact Mail</h4>
                            <p>asksoftsurat@gmail.com</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='footer_link'>
                            <a href='https://www.upwork.com/freelancers/~01eb81c860acb66d90' target="_blank" rel="noreferrer"><i className="bi bi-person-workspace"></i></a>
                            <h4>Hire on Upwork</h4>
                            <p>Check Our Profile</p>
                        </div>
                    </Grid>
                </Grid>
                <div className='addr'>
                    <p>277-Silver Business Hub, <br/> Bapa Sitaram Chowk, Surat, Gujarat 395010 <br />+91 6359 611 740</p>
                </div>
                <div className='copyright'>Website & Mobile App Development Company in India</div>
            </Container>
            
        </footer>
        )
    }