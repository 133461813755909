import React, {useEffect} from 'react';
import { Container } from '@mui/system';

export default function NoMatch() {
    useEffect(() => {
        document.title = '404 Error | Asksoft Web and Mobile App Development Company';
    });
    return (
        <div className='page-content error-page'>
            <Container >
                <i className="bi bi-exclamation-triangle"></i>
                <h1>404</h1>
                <h4>Page Not Found</h4>
            </Container>
        </div>
        )
    }