import React, {useEffect} from 'react';
import { Container } from '@mui/system';
import Slider from "react-slick";
import snapxwallet from '../../assets/images/snapx-wallet.png';
import snapxnetwork from '../../assets/images/snapx-network.png';
import portfolio from '../../assets/images/portfolio.png';

export default function Works() {

    useEffect(() => {
        document.title = 'Work | Asksoft Web and Mobile App Development Company';
    });

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1
              }
            }
          ]
    };

    return (
        <div className="page-content">
             <div className="home-section2 work-page">
                <Container>
                    <div className='heading'>/ EXPERTISE</div>
                    <h2 className='title'>Conversion-focused design, high performance development and consumer experiences.</h2>
                    <Slider {...settings}>
                        <div>
                            <div className="case-study">
                                <img src={portfolio} alt="Portfolio Builder" />
                                <h3>Portfolio Builder</h3>
                                <p>A redesigned and developed website for a preeminent global entertainment company. </p>
                            </div>
                        </div>
                        <div>
                            <div className="case-study">
                            <img src={snapxnetwork} alt="img" />
                                <h3>Snapx Network</h3>
                                <p>A redesigned and developed website for a preeminent global entertainment company. </p>
                            </div>
                        </div>
                        <div>
                            <div className="case-study">
                                <img src={snapxwallet} alt="img" />
                                <h3>Snapx Wallet</h3>
                                <p>A redesigned and developed website for a preeminent global entertainment company. </p>
                            </div>
                        </div>
                    </Slider>

                </Container>
            </div>
        </div>
        )
    }