import React, {useEffect} from 'react';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import aboutimg from '../../assets/images/about.jpg'

export default function About() {
    useEffect(() => {
        document.title = 'About Us | Asksoft Web and Mobile App Development Company';
    });
    return (
        <div className='page-content'>
            <div className='about-page'>
                <Container>
                    <h2 className='title'>About <b>Us</b></h2>
                    <h1>Leading Company In Creating Powerful Software & Technology We are passionate about helping your business reach its technological goals and converting your innovative ideas into digital realities.</h1>
                </Container>
                <img src={aboutimg} alt="Company" />
            </div>
            <div className='what-we-offter'>
                <Container>
                    <h2 className='title'>What We <b>Offer</b></h2>
                    <Grid container spacing={6} alignItems="center">
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>Web Design</h4>
                                <p>Client-centric designs that adapt. Designed for an exceptional and engaging user experience for the web and all mobile platforms.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>Web Development</h4>
                                <p>Full-stack developers ready for all complex web solutions. Utilizing the newest web strategies and frameworks for today's internet standards.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>Mobile App Development</h4>
                                <p>App Design Prototypes, Android & Ios Apps, Native Apps, Cross-Platform Apps, Hybrid Apps, Progressive web Apps</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>SEO Optimization</h4>
                                <p>We can help you build brand authority and visibility. You are only one click away from your target audience.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>Support and Maintenance</h4>
                                <p>Support and maintenance services help to improve customer satisfaction, achieve maximum availability.</p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className='service-box'>
                                <h4>Cloud Services</h4>
                                <p>A wide range of services delivered on demand to companies and customers over the internet</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
        )
    }