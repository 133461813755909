import React, {useEffect} from 'react';
import Slider from "react-slick";
import './App.css';
import './assets/css/style.css';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';

import img1 from './assets/images/img1.svg';
import android from './assets/images/android.svg';
import apple from './assets/images/apple.svg';
import css3 from './assets/images/css3.svg';
import html5 from './assets/images/html5.svg';
import java from './assets/images/java.svg';
import javascript from './assets/images/javascript.svg';
import jquery from './assets/images/jquery.svg';
import laravel from './assets/images/laravel.svg';
import nodejs from './assets/images/nodejs.svg';
import php from './assets/images/php.svg';
import react from './assets/images/react.svg';
import sass from './assets/images/sass.svg';
import bootstrap from './assets/images/bootstrap.svg';
import snapxwallet from './assets/images/snapx-wallet.png';
import snapxnetwork from './assets/images/snapx-network.png';
import portfolio from './assets/images/portfolio.png';

import bingo from './assets/images/bgm-bingo.png';
import snapx from './assets/images/snapx.svg';
import stepgo from './assets/images/step-go.svg';
import portfoliomaker from './assets/images/portfolio-maker.svg';
import elitecoder from './assets/images/elitecoder.svg';

function App() {
    useEffect(() => {
        document.title = 'Asksoft Web and Mobile App Development Company';
    });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 1
          }
        }
      ]
};

const clientlogo = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            slidesToShow: 2
          }
        }
      ]
};

const techBrandList = [
    {
      id: 'a',
      img: android,
    },
    {
      id: 'b',
      img: apple,
     
    },
    {
        id: 'c',
        img: bootstrap,
       
      },
      {
        id: 'd',
        img: css3,
       
      },
      {
        id: 'f',
        img: html5,
       
      },
      {
        id: 'g',
        img: java,
       
      },
      {
        id: 'h',
        img: javascript,
       
      },
      {
        id: 'i',
        img: jquery,
       
      },
      {
        id: 'j',
        img: laravel,
       
      },
      {
        id: 'k',
        img: nodejs,
       
      },
      {
        id: 'l',
        img: php,
       
      },
      {
        id: 'm',
        img: react,
       
      },
      {
        id: 'n',
        img: sass,
       
      },
];


  return (
    <div className="App">
        <div className="home-section1">
            <Container>
                <Grid container alignItems="center" spacing={2} columns={12}>
                    <Grid item sm={12} md={6}>
                        <div className='heading'>/ DIGITAL AGENCY</div>
                        <h1>We move the <b>Digital Industry</b> Forward.</h1>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <img src={img1} alt="img" />
                    </Grid>
                </Grid>
                <Slider {...clientlogo}>
                    <div>
                        <div className='client-logo'>
                            <img src={bingo} alt='Bingo Mobile App Development' />                               
                        </div>
                    </div>
                    <div>
                        <div className='client-logo'>
                            <img src={snapx} alt='Snapx Walk & Activity Tracker' />                               
                        </div>
                    </div>
                    <div>
                        <div className='client-logo'>
                        <img src={stepgo} alt='Step Go Walk & Earn' />                               
                        </div>
                    </div>
                    <div>
                        <div className='client-logo'>
                            <img src={portfoliomaker} alt='Online Free Portfolio Website Maker' />                               
                        </div>
                    </div>
                    <div>
                        <div className='client-logo'>
                            <img src={elitecoder} alt='Elitecoder IT Software Company' />                               
                        </div>
                    </div>
                    <div>
                        <div className='client-logo'>
                            <img src={snapx} alt='Snapx Walk & Activity Tracker' />                               
                        </div>
                    </div>
                </Slider>
            </Container>
        </div>

        <div className="home-section2">
            <Container>
                <div className='heading'>/ EXPERTISE</div>
                <h2 className='title'>Conversion-focused design, high performance development and consumer experiences.</h2>
                <Slider {...settings}>
                    <div>
                        <div className="case-study">
                            <img src={portfolio} alt="Portfolio Builder" />
                            <h3>Portfolio Builder</h3>
                        </div>
                    </div>
                    <div>
                        <div className="case-study">
                        <img src={snapxnetwork} alt="img" />
                            <h3>Snapx Network</h3>
                        </div>
                    </div>
                    <div>
                        <div className="case-study">
                            <img src={snapxwallet} alt="img" />
                            <h3>Snapx Wallet</h3>
                        </div>
                    </div>
                </Slider>

            </Container>
        </div>

        <div className="home-section3">
            <Container>
                <div className='heading'>/ How can we help?</div>
                <h2 className='title'>We help small & <b>big businesses</b></h2>

                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                        <i className="bi bi-paint-bucket"></i>
                            <h4>Web Design</h4>
                            <p>Client-centric designs that adapt. Designed for an exceptional and engaging user experience for the web and all mobile platforms.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                            <i className="bi bi-code-slash"></i>
                            <h4>Web Development</h4>
                            <p>Full-stack developers ready for all complex web solutions. Utilizing the newest web strategies and frameworks for today's internet standards.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                        <i className="bi bi-phone"></i>
                            <h4>Mobile App Development</h4>
                            <p>App Design Prototypes, Android & Ios Apps, Native Apps, Cross-Platform Apps, Hybrid Apps, Progressive web Apps</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                        <i className="bi bi-search"></i>
                            <h4>SEO Optimization</h4>
                            <p>We can help you build brand authority and visibility. You are only one click away from your target audience.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                        <i className="bi bi-headset"></i>
                            <h4>Support and Maintenance</h4>
                            <p>Support and maintenance services help to improve customer satisfaction, achieve maximum availability.</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='service-box'>
                            <i className="bi bi-clouds"></i>
                            <h4>Cloud Services</h4>
                            <p>A wide range of services delivered on demand to companies and customers over the internet</p>
                        </div>
                    </Grid>
                </Grid>

            </Container>
        </div>

        <div className="home-section4">
            <Container>
                <div className='heading'>/ How can we help?</div>
                <h2 className='title'>Our <b>Technology</b></h2>

                <ul className='tech_list'>
                    {techBrandList.map(item => (
                    <li key={item.id}>
                        <img src={item.img} alt={item.img} />
                    </li>
                    ))}
                </ul>
            </Container>
        </div>
    </div>
  );
}

export default App;
