import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import Works from "./Components/Pages/Works";
import Services from "./Components/Pages/Services";
import Contact from "./Components/Pages/Contact";
import NoMatch from "./Components/Pages/NoMatch";
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./ScrollToTop.js";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Header />
    <ScrollToTop />
    <Routes>
      <Route exact path="/" element={<App />} />
      <Route path="home" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="works" element={<Works />} />
      <Route path="services" element={<Services />} />
      <Route path="contact" element={<Contact />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
    <Footer />
  </BrowserRouter>
  
);

reportWebVitals();
