import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
// import Axios from 'axios';

function Contact() {
  useEffect(() => {
    document.title = 'Contact | Asksoft Web and Mobile App Development Company';
  });
    // const url =""
    // const [data, setData] = useState({
    //   name: "",
    //   email: "",
    //   message: ""
    // })

    // function handle(e){
    //   const newdata={...data}
    //   newdata=[e.target.id] = e.target.value
    //   setData(newdata)
    //   console.log(newdata)
    // }
   


    return(
      <div className="page-content">
        <div className='contact-form'>
          <Container>
            <div className='heading'>/ How can we help?</div>
            <h2 className='title'>Contact <b>Us</b></h2>
            <form >
              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                  <div className='form-group'>
                    <label>Name</label>
                    <input  id="name" type="text" className='form-control' />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <div className='form-group'>
                    <label>Email</label>
                    <input id="email" type="email" className='form-control' />
                  </div>
                </Grid>
              </Grid>
              <div className='form-group'>
                <label>Message</label>
                <textarea  id="message" type="message" className='form-control' />
              </div>
              <button className='button' type="submit">Submit</button>
            </form>
          </Container>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14877.399665022976!2d72.8950358!3d21.2179686!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbdd3caeabafaa604!2sASKsoft.in!5e0!3m2!1sen!2sin!4v1656753671768!5m2!1sen!2sin" title="google map" width="100%" height="450" style={{border:0}} aria-hidden="false"></iframe>
      </div>
    );
}
export default Contact;