import React, {useState} from 'react';
import './Header.css';
import logo from '../../assets/images/logo-white.svg';
import { Container } from '@mui/system';
import { Link } from "react-router-dom";

export default function Header() {
    const [isActive, setIsActive] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };

    return (
        <div className='header'>
            <Container className='d-flex'>
                <div className='logo'>
                    <Link to="/"><img src={logo} alt="logo" /></Link>
                </div>
                <div className={isActive ? 'mobile-menu' : 'menu'}>
                    <Link onClick={handleClick} to="/about">About</Link>
                    <Link onClick={handleClick} to="/works">Works</Link>
                    <Link onClick={handleClick} to="/services">Services</Link>
                    <Link onClick={handleClick} to="/contact">Contact us</Link>
                </div>
                <div className='hired_btn'>
                    <a href="https://www.upwork.com/freelancers/~01eb81c860acb66d90" target="_blank" rel="noreferrer"><i className='bi bi-person-workspace'></i> Hire on Upwork</a>
                </div>
                <div className='menu-bar' onClick={handleClick}>
                    <i className="bi bi-list"></i>
                </div>
            </Container>
        </div>
        )
    }